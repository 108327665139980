document.addEventListener("DOMContentLoaded", () => {
  const slogans = document.querySelector(".hero-main .line--2");
  const dataText = JSON.parse(slogans.getAttribute("data-text"));

  let textAnimationTimeout;
  let currentTextIndex = 0;

  const typeWriter = (text, i, fnCallback) => {
    if (document.body.classList.contains("animations-paused")) {
      slogans.textContent = dataText[dataText.length - 1];
      return;
    }

    if (i < text.length) {
      slogans.innerHTML = `${text.substring(0, i + 1)}<span aria-hidden="true"></span>`;
      textAnimationTimeout = setTimeout(() => typeWriter(text, i + 1, fnCallback), 120);
    } else if (typeof fnCallback === "function") {
      textAnimationTimeout = setTimeout(fnCallback, 2000);
    }
  };

  const startTextAnimation = (i) => {
    if (document.body.classList.contains("animations-paused")) {
      slogans.textContent = dataText[dataText.length - 1];
      return;
    }

    currentTextIndex = i;
    if (i >= dataText.length) {
      textAnimationTimeout = setTimeout(() => startTextAnimation(0), 4000);
    } else {
      typeWriter(dataText[i], 0, () => startTextAnimation(i + 1));
    }
  };

  startTextAnimation(0);
});

window.addEventListener("scroll", () => {
  const heroElement = document.querySelector(".hero-main");
  const scrollY = window.scrollY;
  const windowHeight = window.innerHeight;

  const startFadeHeight = windowHeight * 0.05;
  const endFadeHeight = windowHeight * 0.65;

  if (scrollY >= startFadeHeight) {
    heroElement.style.opacity = Math.max(0, 1 - (scrollY - startFadeHeight) / (endFadeHeight - startFadeHeight));
  } else {
    heroElement.style.opacity = 1;
  }
});
