import Swiper from "swiper";
import { Scrollbar, Navigation } from "swiper/modules";

const featuresCarousels = document.querySelectorAll(".features__carousel");

// Function to adjust the height of all h3 elements
const adjustH3Height = (carousel) => {
  const h3Elements = carousel.querySelectorAll("h3");
  let maxHeight = 0;

  // Reset heights to calculate the max height accurately
  h3Elements.forEach((h3) => {
    h3.style.height = "auto";
    maxHeight = Math.max(maxHeight, h3.offsetHeight);
  });

  // Set all h3 elements to the maximum height
  h3Elements.forEach((h3) => {
    h3.style.height = `${maxHeight}px`;
  });
};

// Initialize Swiper and adjust heights
featuresCarousels?.forEach((carousel) => {
  const scrollbarElement = carousel.querySelector(".swiper-scrollbar");
  const arrowPrev = carousel.querySelector(".prev");
  const arrowNext = carousel.querySelector(".next");
  const swiper = carousel.querySelector(".swiper");

  new Swiper(swiper, {
    modules: [Scrollbar, Navigation],
    slidesPerView: "auto",
    spaceBetween: 28,
    slidesOffsetBefore: 28,
    slidesOffsetAfter: 28,
    rewind: true,
    scrollbar: {
      el: scrollbarElement,
      draggable: true,
    },
    navigation: {
      nextEl: arrowNext,
      prevEl: arrowPrev,
    },
    breakpoints: {
      1024: {
        slidesOffsetBefore: 46,
        slidesOffsetAfter: 20,
      },
    },
    on: {
      init: () => adjustH3Height(carousel),
    },
  });

  // Adjust heights on window resize
  window.addEventListener("resize", () => adjustH3Height(carousel));
});
