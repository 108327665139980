import Swiper from "swiper";
import { Pagination, Autoplay } from "swiper/modules";

const supportingTechnologyCarousels = document.querySelectorAll(".supporting-technology__logos .swiper");

supportingTechnologyCarousels?.forEach((carousel) => {
  new Swiper(carousel, {
    modules: [Pagination, Autoplay],
    slidesPerView: 2,
    spaceBetween: 20,
    centerInsufficientSlides: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    breakpoints: {
      782: {
        slidesPerView: 4,
      },
      1024: {
        slidesPerView: 6,
        spaceBetween: 36,
      },
    },
  });
});
