import "../styles/main.scss";
import "swiper/css";
import "glightbox/dist/css/glightbox.min.css";

import SimpleParallax from "simple-parallax-js/vanilla";

document.addEventListener("DOMContentLoaded", function () {
  const blocks = import.meta.glob("../../blocks/**/script.js", { eager: true });

  const siteHeader = document.querySelector(".site-header");
  let headerHeight = siteHeader.offsetHeight;
  let lastScrollTop = 0;
  let activeSectionId = null;
  const sections = document.querySelectorAll("section");
  const menuItems = document.querySelectorAll("#menu-primary li a");

  const checkHeaderHeight = () => {
    headerHeight = siteHeader.offsetHeight;
  };

  const handleHeaderScroll = () => {
    const scrollTop = window.scrollY;

    if (scrollTop > headerHeight + 10) {
      siteHeader.classList.add("fixed");
    } else {
      siteHeader.classList.remove("fixed");
    }

    if (scrollTop < lastScrollTop && scrollTop > headerHeight * 6) {
      siteHeader.classList.add("show");
    } else if (scrollTop > lastScrollTop) {
      siteHeader.classList.remove("show");
    }

    lastScrollTop = scrollTop;
  };

  const handleSectionScroll = () => {
    let currentSectionId = null;

    sections.forEach((section) => {
      const rect = section.getBoundingClientRect();
      if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
        if (!currentSectionId || rect.top < document.querySelector(`#${currentSectionId}`).getBoundingClientRect().top) {
          currentSectionId = section.getAttribute("id");
        }
      }
    });

    if (currentSectionId !== activeSectionId) {
      if (activeSectionId) {
        document.querySelector(`#menu-primary li a[href="#${activeSectionId}"]`).parentElement.classList.remove("active");
      }
      if (currentSectionId) {
        document.querySelector(`#menu-primary li a[href="#${currentSectionId}"]`).parentElement.classList.add("active");
      }
      activeSectionId = currentSectionId;
    }
  };

  window.addEventListener("resize", checkHeaderHeight);
  window.addEventListener("scroll", handleHeaderScroll);
  window.addEventListener("scroll", handleSectionScroll);

  menuItems.forEach((item) => {
    item.addEventListener("click", (event) => {
      menuItems.forEach((i) => i.parentElement.classList.remove("active"));
      event.target.parentElement.classList.add("active");
    });
  });

  handleHeaderScroll();
  handleSectionScroll();

  // PARALLAX HANDLING

  let parallaxInstances = [];

  const initParallax = () => {
    const parallaxBottom = document.querySelectorAll(".parallax-bottom > img");
    const parallaxBig = document.querySelectorAll(".parallax-big > img");
    const parallaxTop = document.querySelectorAll(".parallax-top > img");
    const parallaxLeft = document.querySelectorAll(".parallax-left > img");
    const parallaxRight = document.querySelectorAll(".parallax-right > img");

    parallaxInstances = [
      new SimpleParallax(parallaxBottom, { orientation: "down", customWrapper: "figure", delay: 0.6 }),
      new SimpleParallax(parallaxTop, { customWrapper: "figure", delay: 0.6 }),
      new SimpleParallax(parallaxLeft, { orientation: "left", customWrapper: "figure", delay: 0.6 }),
      new SimpleParallax(parallaxRight, { orientation: "right", customWrapper: "figure", delay: 0.6 }),
      new SimpleParallax(parallaxBig, { customWrapper: "figure", scale: 1.5 }),
    ];
  };

  initParallax();

  // MODAL HANDLING

  const modals = document.querySelectorAll(".modal");
  const links = document.querySelectorAll("a[href^='#']");

  modals.forEach((modal) => {
    const modalId = modal.getAttribute("id");

    links.forEach((link) => {
      if (link.getAttribute("href") === `#${modalId}`) {
        link.setAttribute("popovertarget", modalId);
        link.addEventListener("click", (event) => {
          event.preventDefault();
          modal.showPopover();
        });
      }
    });

    const closeButtons = modal.querySelectorAll(".close");
    closeButtons.forEach((closeButton) => {
      closeButton.addEventListener("click", () => {
        modal.hidePopover();
      });
    });
  });

  // PAUSE ANIMATIONS BUTTON

  const pauseButton = document.getElementById("pause");
  let animationsPaused = false;

  pauseButton.addEventListener("click", () => {
    pauseButton.classList.toggle("active");
    animationsPaused = !animationsPaused;

    document.body.classList.toggle("animations-paused", animationsPaused);

    if (animationsPaused) {
      document.querySelectorAll("[data-fade]").forEach((el) => {
        el.style.animationPlayState = "paused";
        el.style.transition = "none";
      });

      parallaxInstances.forEach((instance) => instance.destroy());
    } else {
      document.querySelectorAll("[data-fade]").forEach((el) => {
        el.style.animationPlayState = "running";
        el.style.transition = "";
      });
      initParallax();
    }
  });

  document.querySelectorAll('a[href^="#"]')?.forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();
      const targetId = this.getAttribute("href");
      const targetElement = document.querySelector(targetId);
      if (targetElement) {
        let offsetPosition = targetElement.offsetTop;
        let parent = targetElement.offsetParent;

        while (parent) {
          offsetPosition += parent.offsetTop;
          parent = parent.offsetParent;
        }

        window.scrollTo({
          top: offsetPosition - 130,
          behavior: "smooth",
        });
      }
    });
  });
});
